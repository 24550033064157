import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { IEntityUser } from 'modules/user/models/user.model';

@Directive({
  selector: 'user-avatar',
})
export class UsersAvatarDirective extends UpgradeComponent {
  static readonly selector = 'userAvatar';

  @Input() user: { id: number; firstName?: string; lastName?: string; title?: string };
  @Output() userChange = new EventEmitter<IEntityUser>();
  @Input() size!: number;
  @Input() skipDefault!: boolean;
  @Input() showInitials!: boolean;
  @Input() hideTitle!: boolean;
  @Input() isTransparent?: boolean;

  constructor(elementRef: ElementRef, injector: Injector) {
    super(UsersAvatarDirective.selector, elementRef, injector);
  }
}
