import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { CloseCallback } from 'components/forms/click-confirmation.directive';
import { ElmsUtils } from 'core/utils';
import { IExternalApplication } from 'modules/external-applications/models/external-application.model';
import { AddExternalApplicationKeyModalComponent } from 'modules/user/external-applications/edit/components/modal/add-external-application-key-modal.component';
import { IExternalApplicationKey } from 'modules/user/external-applications/models/external-application-key.model';
import { ExternalApplicationKeyService } from 'modules/user/external-applications/services/external-application-key.service';
import moment from 'moment';
import { EMPTY, Unsubscribable, catchError, finalize, from, tap } from 'rxjs';

@Component({
  selector: 'external-application-keys',
  templateUrl: 'external-application-keys.component.html',
})
export class ExternalApplicationKeysComponent implements OnInit, OnDestroy {
  @Input() application: IExternalApplication;

  loading = false;
  apiKeys: IExternalApplicationKey[] = [];
  newKey: IExternalApplicationKey = null;
  secretKeyInputType = 'password';

  private subscriber?: Unsubscribable;
  constructor(
    private keyService: ExternalApplicationKeyService,
    private ngbModalService: NgbModal,
    private notificationService: NotificationService,
    private currentUser: CurrentUserService,
  ) {}

  get request_url(): string {
    return `${window.location.protocol}//${window.location.host}/api/v2/users/`;
  }

  ngOnInit(): void {
    this._loadKeys();
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  addKey(): void {
    if (this.newKey) {
      this.apiKeys.push(this.newKey);
      this.newKey = null;
    }

    const modalReference: NgbModalRef = this.ngbModalService.open(AddExternalApplicationKeyModalComponent, {
      backdrop: 'static',
    });

    (<AddExternalApplicationKeyModalComponent>modalReference.componentInstance).application = this.application;

    from(modalReference.result)
      .pipe(catchError(() => EMPTY))
      .subscribe((newKey) => {
        this.newKey = newKey;
      });
  }

  getExpirationPeriod(key: IExternalApplicationKey) {
    return key.expires ? moment(key.expires).fromNow() : '';
  }

  expired(key: IExternalApplicationKey) {
    return key.expires && moment(key.expires).isBefore(moment());
  }

  copyAccessToken(): void {
    ElmsUtils.copyToClipboard(this.newKey.key)
      .pipe(tap(() => this.notificationService.info('API key copied to clipboard', 3e3)))
      .subscribe();
  }

  copyKeySecret(): void {
    ElmsUtils.copyToClipboard(this.newKey.secretPlain)
      .pipe(tap(() => this.notificationService.info('API key secret copied to clipboard', 3e3)))
      .subscribe();
  }

  toggleSecretKeyVisibility(): void {
    this.secretKeyInputType = this.secretKeyInputType === 'password' ? 'text' : 'password';
  }

  deleteKey(key: IExternalApplicationKey, callback: CloseCallback) {
    this.keyService
      .delete(this.application.id, this.currentUser.get().id, key.id, true)
      .pipe(
        catchError(() => {
          this.notificationService.error('Failed to delete API key', 3e3);

          return EMPTY;
        }),
        finalize(() => {
          this._loadKeys();
          callback();
        }),
      )
      .subscribe(() => {
        this.notificationService.info('API key deleted', 3e3);
      });
  }

  private _loadKeys(): void {
    this.loading = true;

    this.subscriber = this.keyService
      .query(this.application.id, this.currentUser.get().id)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.subscriber.unsubscribe();
          delete this.subscriber;
        }),
      )
      .subscribe((keys) => {
        this.apiKeys = keys;
      });
  }
}
