import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { GlobalConfig } from 'core/environment';
import { ElmsUtils } from 'core/utils';
import _ from 'lodash';
import { QuizQuestionType, questionTypeExtension } from 'modules/quiz';
import { IQuizResult } from 'modules/quiz/models/quiz.model';
import { QuizPlayer, QuizPlayerEvent, QuizPlayerService } from 'modules/quiz/services/quiz-player.service';
import { QuizResultService } from 'modules/quiz/services/quiz-result.service';
import moment from 'moment';
import { Subject, Unsubscribable, finalize, fromEvent, interval, map, merge, take, tap, timer } from 'rxjs';

export interface IQuizPlayerFinishParams {
  allowMoveToTheNextStep?: boolean;
}

@Component({
  selector: 'quiz-player-view',
  templateUrl: './quiz-player.component.html',
})
export class QuizPlayerComponent implements OnInit, OnDestroy {
  static readonly selector = 'quizPlayerView';
  @Input() resultId: number;
  @Input() introText: string;
  @Input() warningText: string;
  @Input() lostConnectionText: string;
  @Input() scoreTypeId: number;
  @Input() customPlayerSteps: string[];

  @Output() finishHandler = new EventEmitter<boolean>();

  quizQuestionType = QuizQuestionType;
  questionTypeExtension = questionTypeExtension;
  timeTrackingAttemptsEnabled = !!this.globalConfig.settings?.quizOptions?.timeTrackingAttemptsEnabled;

  browserIsOnline = true;

  limitHours: number;
  limitMinutes: number;
  timeLeftHours: number;
  timeLeftMinutes: number;
  timeLeftSeconds: number;

  player: QuizPlayer;
  error: string;
  quizResult: IQuizResult;
  duration: moment.Duration;
  loading = '';
  private subscribers: Unsubscribable[] = [];
  private countDownTimerInterval?: Unsubscribable;

  constructor(
    public quizResultService: QuizResultService,
    private quizPlayerService: QuizPlayerService,
    private notificationService: NotificationService,
    private globalConfig: GlobalConfig,
    private window: Window,
  ) {}

  @Input() set quizRetake(subject: Subject<{ resultId: number; skipStartStep?: boolean }>) {
    if (!subject) {
      return;
    }

    this.subscribers.push(
      subject.subscribe((event) => {
        this.onEventQuizRetake(event.resultId, event.skipStartStep);
      }),
    );
  }

  @Input() set quizSkip(subject: Subject<void>) {
    if (!subject) {
      return;
    }

    this.subscribers.push(
      subject.subscribe(() => {
        this.onQuizSkip();
      }),
    );
  }

  @Input() set quizFinish(subject: Subject<boolean>) {
    if (!subject) {
      return;
    }

    this.subscribers.push(
      subject.subscribe((handleQuizResult) => {
        this.onQuizFinish(handleQuizResult);
      }),
    );
  }

  @Input() set playerClose(subject: Subject<void>) {
    if (!subject) {
      return;
    }

    this.subscribers.push(
      subject.subscribe(() => {
        this.beforeCloseHandler();
      }),
    );
  }

  ngOnInit() {
    if (this.resultId) {
      this.subscribers.push(this.loadQuizResult(this.resultId).subscribe());
    } else {
      this.error = 'There is no result created.';
    }

    this.subscribers.push(
      merge(
        fromEvent(this.window, 'online').pipe(map(() => true)),
        fromEvent(this.window, 'offline').pipe(map(() => false)),
      ).subscribe((status) => {
        this.onBrowserOnlineStatusChanged(status);
      }),
    );
  }

  loadQuizResult(resultId: number) {
    return this.quizResultService.get(resultId).pipe(
      tap((quizResult) => {
        this.quizResult = quizResult;
        this.initPlayer(quizResult);
      }),
    );
  }

  ngOnDestroy() {
    this.subscribers.forEach((subscriber) => subscriber.unsubscribe());
    this.subscribers.length = 0;

    this.player.stopTimeTracking();
    this.stopCountdown();
  }

  nextQuestion() {
    if (!this.loading) {
      this.loading = 'loading';

      const timerObs = timer(1000)
        .pipe(
          take(1),
          tap(() => (this.loading = 'nextQuestion')),
        )
        .subscribe(() => {
          timerObs.unsubscribe();
        });

      this.subscribers.push(
        this.player
          .nextQuestion()
          .pipe(
            finalize(() => {
              this.loading = '';
              timerObs.unsubscribe();
            }),
          )
          .subscribe(),
      );
    }
  }

  showQuestionCorrectness() {
    return (
      this.quizResult.quiz &&
      (this.quizResult.quiz.scoreTypeId || this.scoreTypeId) &&
      this.player.currentQuestion.typeId !== this.quizQuestionType.shortAnswer &&
      this.player.currentQuestion.typeExtensionId !== this.questionTypeExtension.likertScale.id &&
      this.quizResultService.isCompleted(this.quizResult) &&
      (this.quizResult.quiz.showQuestionCorrectness === 1 ||
        (this.quizResult.quiz.showQuestionCorrectness === 2 &&
          (this.quizResult.attempt === this.quizResult.quiz.attemptsAllowed ||
            [2, 3].includes(this.quizResult.statusId))))
    );
  }

  formatDigit(digit: number) {
    return ElmsUtils.formatDigit(digit);
  }

  beforeCloseHandler() {
    if (
      this.player.result.quiz.timeLimit > 0 &&
      !this.quizResultService.isCompleted(this.quizResult) &&
      !['start', 'continue'].includes(this.player.playerCurrentStep)
    ) {
      if (this.timeTrackingAttemptsEnabled && !this.player.result.quiz.resumeAllowed) {
        this.quizResultService.finish(this.player.result).subscribe((result) => {
          this.player.emit({ type: QuizPlayerEvent.finished, quizResult: result });
        });
      } else {
        this.quizResultService.timeTracking(this.player.result.id).subscribe();
      }
    }
  }

  onEventQuizRetake(resultId: number, skipStartStep: boolean) {
    if (resultId) {
      this.resultId = resultId;
      const quizResultObservable = this.loadQuizResult(resultId);

      if (skipStartStep) {
        quizResultObservable.pipe(
          tap(() => {
            this.player.start();
          }),
        );
      }

      this.subscribers.push(quizResultObservable.subscribe());
    }
  }

  onQuizFinish(handleQuizResult: boolean) {
    this.player.finish(handleQuizResult);
  }

  onQuizSkip() {
    this.player.skip();
  }

  private initPlayer(quizResult: IQuizResult) {
    Object.freeze(quizResult.quiz); // prevents to change timeLimit or attemptsAllowed

    this.player = this.quizPlayerService.getPlayer(quizResult);
    this.player.customPlayerSteps = this.customPlayerSteps;

    if (quizResult.quiz.timeLimit > 0) {
      this.limitHours = Math.floor(quizResult.quiz.timeLimit / 60);
      this.limitMinutes = quizResult.quiz.timeLimit % 60;

      if (quizResult.spentSeconds) {
        this.timeLeftHours = Math.floor((quizResult.quiz.timeLimit * 60 - quizResult.spentSeconds) / 3600);
        this.timeLeftMinutes = Math.floor((quizResult.quiz.timeLimit * 60 - quizResult.spentSeconds) / 60) % 60;
        this.timeLeftSeconds = (quizResult.quiz.timeLimit * 60 - quizResult.spentSeconds) % 60;
      }
    }

    this.subscribers.push(
      this.player.on(QuizPlayerEvent.finished).subscribe((event) => {
        this.finishHandler.emit(event.handleQuizResult);
      }),
    );

    this.subscribers.push(
      this.player.on(QuizPlayerEvent.error).subscribe((error) => {
        this.error = error.message;
      }),
    );

    this.subscribers.push(
      this.player.on(QuizPlayerEvent.questionChanged).subscribe(() => {
        if (this.player.currentQuestion && this.player.currentQuestion.answerVariants) {
          this.player.currentQuestion.selectedChoice = _.find(this.player.currentQuestion.answerVariants, 'selected');
        }
      }),
    );

    this.subscribers.push(
      this.player.on(QuizPlayerEvent.started).subscribe(() => {
        this.startCountdown();
      }),
    );
  }

  private startCountdown() {
    this.countDownTimerInterval = interval(500).subscribe(() => {
      if (this.player.endTime && ['questions', 'pendingCompletion'].includes(this.player.playerCurrentStep)) {
        if (moment().isAfter(this.player.endTime) || this.quizResultService.isCompleted(this.quizResult)) {
          this.player.playerCurrentStep = 'timeEnds';
          this.duration = null;
        } else {
          this.duration = moment.duration(this.player.endTime.diff(moment()));
        }
      }
    });
  }

  private stopCountdown() {
    this.countDownTimerInterval?.unsubscribe();
    delete this.countDownTimerInterval;
  }

  private onBrowserOnlineStatusChanged(browserIsOnline: boolean) {
    if (this.browserIsOnline === browserIsOnline) {
      return;
    }

    this.browserIsOnline = browserIsOnline;

    if (browserIsOnline) {
      this.notificationService.hideError();
      this.notificationService.info('The internet connection was restored.', 2000);
    } else {
      const notificationText =
        'The internet connection was lost. Please close this window and try again.' +
        'Your progress has been saved up to this point.';

      if (this.lostConnectionText) {
        this.notificationService.error(this.lostConnectionText, 5e3);
      } else {
        this.notificationService.error(notificationText, 5e3);
      }
    }
  }
}
