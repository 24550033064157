import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { Unsubscribable, finalize, tap } from 'rxjs';
import { ActivityOrigin, IUserNetworkActivity } from '../../../network/models/user-network-activity.model';
import { NetworkRotatorService } from '../../../network/services/network-rotator.service';
import { NetworkService } from '../../../network/services/network.service';
import { UserNetworkActivityService } from '../../../network/services/user-network-activity.service';
import { IUser } from '../../models/user.model';

export interface IActivityFilter {
  name: string;
  value: string;
}

@Component({
  selector: 'user-network',
  templateUrl: './user-network.component.html',
  providers: [
    {
      provide: 'activityFilters',
      useValue: [
        {
          name: 'Recent',
          value: 'recent',
        },
        {
          name: 'Posts',
          value: 'post',
        },
        {
          name: 'Blog Posts',
          value: 'blog_post',
        },
        {
          name: 'Course Recommendations',
          value: 'course_recommendation',
        },
        {
          name: 'Course Registration',
          value: 'course_registration',
        },
        {
          name: 'Course Completion',
          value: 'course_completion',
        },
        {
          name: 'Course Posts',
          value: 'course_post',
        },
      ],
    },
  ],
})
export class UserNetworkComponent implements OnInit, OnDestroy {
  @Input() user?: IUser;
  @Input() observedUserId?: string;
  @Input() activities: IUserNetworkActivity[] = [];

  currentUserId = this.currentUser.get().id;
  selfView = false;
  profileCompleted = true;
  filterType = 'recent';
  query: string;
  searchRequestSubscriber?: Unsubscribable;
  itemCount = 0;
  page = 1;
  readonly itemsPerPage = 10;
  readonly trackingName = 'user-network';
  readonly activityOrigin: ActivityOrigin = 'user';

  constructor(
    private networkService: NetworkService<IUserNetworkActivity>,
    private currentUser: CurrentUserService,
    @Inject('activityFilters') public activityFilters: IActivityFilter[],
    private backUrlService: BackUrlService,
    private userNetworkActivityService: UserNetworkActivityService,
    private networkRotatorService: NetworkRotatorService,
  ) {}

  ngOnInit(): void {
    this.selfView = this.currentUserId === this.user?.id;
    this.profileCompleted = this.networkRotatorService.getNetworkProfileCompleteness(this.user) >= 100;
    this.search();
  }

  ngOnDestroy(): void {
    this.searchRequestSubscriber?.unsubscribe();
  }

  goBack() {
    this.backUrlService.goBack();
  }

  search() {
    this.searchRequestSubscriber = this.searchImpl()
      .pipe(
        finalize(() => {
          this.searchRequestSubscriber.unsubscribe();
          delete this.searchRequestSubscriber;
        }),
      )
      .subscribe();
  }

  pageChanged(page: number) {
    this.page = page;
    this.search();
  }

  prepareNewPost() {
    this.networkService
      .showEditThreadDialog(this.userNetworkActivityService.newPost({ activityOrigin: this.activityOrigin }))
      .then((newActivity) => {
        this.page = 1;
        this.searchRequestSubscriber = this.searchImpl()
          .pipe(
            tap(() => {
              if (!this.activities.find((activity) => activity.id.toString() === newActivity.id.toString())) {
                this.activities.unshift(newActivity);
              }
            }),
            finalize(() => {
              this.searchRequestSubscriber.unsubscribe();
              delete this.searchRequestSubscriber;
            }),
          )
          .subscribe();
      });
  }

  private searchImpl() {
    const query = {
      activityOrigin: this.activityOrigin,
      filter: { type: this.filterType },
      offset: this.itemsPerPage * (this.page - 1),
      take: this.itemsPerPage,
    };

    if (this.query) {
      query['query'] = this.query;
    }

    if (this.observedUserId) {
      query['userId'] = this.observedUserId;
    }

    return this.userNetworkActivityService.query(query).pipe(
      tap((response) => {
        this.activities = response.items;
        this.itemCount = response.count;
      }),
    );
  }
}
