import { Component, Input, OnInit } from '@angular/core';
import { ISearchFilter, ISearchFilterService } from 'modules/search/models/search-filters.models';

@Component({
  selector: 'dates-choice-filter',
  templateUrl: './dates-choice-filter.component.html',
})
export class DatesChoiceFilterComponent implements OnInit {
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
  @Input() filterService: ISearchFilterService;

  filter: ISearchFilter;

  ngOnInit(): void {
    this.filter = this.filterService.filter;
  }

  applyFilter(value: string, term: string) {
    this.filterService.applyFilter(value, term);
  }
}
