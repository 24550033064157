import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { ISearchFilter, ISearchFilterItem, ISearchFilterService } from 'modules/search/models/search-filters.models';

@Component({
  selector: 'course-attribute-filter',
  templateUrl: './course-attribute-filter.component.html',
})
export class CourseAttributeFilterComponent implements OnInit {
  @Input() filterService: ISearchFilterService;
  title: string;
  items: ISearchFilterItem[] = [];
  modalInstance?: NgbActiveModal;
  filter: ISearchFilter;

  get isModal(): boolean {
    return !!this.modalInstance;
  }

  ngOnInit() {
    this.filter = this.filterService.filter;
    this.items = this.filter.items;
    this.title = this.filter.label;

    this.items.forEach((item) => {
      item.selected = _.some(this.filter.selectedItems, { id: item.id });
    });
  }

  onCheckedChanged(item: ISearchFilterItem) {
    if (!this.modalInstance) {
      this.filterService.applyFilter(item.id.toString());
    }
  }

  getCheckedNodes(nodes: ISearchFilterItem[]): ISearchFilterItem[] {
    return _.filter(nodes, (n) => {
      return n.selected && n.id;
    }) as ISearchFilterItem[];
  }

  applyFilter() {
    this.filterService.clear();

    _.forEach(this.getCheckedNodes(this.items), (item) => {
      this.filterService.applyFilter(item.id.toString());
    });

    if (this.modalInstance) {
      this.modalInstance.close();
    }
  }

  cancelModal() {
    this.modalInstance.dismiss('cancel');
  }
}
