import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { Unsubscribable, finalize, iif } from 'rxjs';
import { NetworkSubscriptionService } from '../../../network/services/network-subscription.service';
import { IUser } from '../../models/user.model';

@Component({
  selector: 'user-profile-short-info',
  templateUrl: './user-profile-short-info-component.html',
})
export class UserProfileShortInfoComponent implements OnInit, OnDestroy {
  @Input() user: IUser;
  @Input() trackingName: string;

  selfView = false;
  subscription?: Unsubscribable;
  constructor(
    private currentUserService: CurrentUserService,
    private networkSubscriptionService: NetworkSubscriptionService,
    private stateService: StateService,
  ) {}

  ngOnInit(): void {
    this.selfView = this.user.id === this.currentUserService.get().id;
  }

  changeSubscription(): void {
    this.subscription = iif(
      () => this.user.userNetwork.observed,
      this.networkSubscriptionService.removeSubscription(this.user.id, this.currentUserService.get().id, 'user'),
      this.networkSubscriptionService.createSubscription(this.user.id, this.currentUserService.get().id, 'user'),
    )
      .pipe(
        finalize(() => {
          this.subscription?.unsubscribe();
          delete this.subscription;
        }),
      )
      .subscribe(() => {
        this.user.userNetwork.observed = !this.user.userNetwork.observed;
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  goEdit(): void {
    this.stateService.go('edit.account', { activeView: 'mainNetworkProfileForm' });
  }
}
