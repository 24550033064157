import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { ISearchFilter, ISearchFilterItem, ISearchFilterService } from 'modules/search/models/search-filters.models';
import { Unsubscribable } from 'rxjs';

interface INode {
  id: number;
  checked: boolean;
  expanded: boolean;
  items: INode[];
  parent: INode;
  typeId?: number;
}

@Component({
  selector: 'group-tags-search-filter',
  templateUrl: './group-tags-filter.component.html',
})
export class GroupTagsFilterComponent implements OnInit, OnDestroy {
  @Input() filterService: ISearchFilterService;

  groupTags: INode[];
  filter: ISearchFilter;
  modalInstance?: NgbActiveModal;
  selectedItems: ISearchFilterItem[];
  treeControl = new NestedTreeControl<INode>((node) => node.items);

  private subscriber: Unsubscribable;

  get isModal(): boolean {
    return !!this.modalInstance;
  }

  hasChild = (_: number, node: INode) => !!node.items && node.items.length > 0;

  ngOnInit(): void {
    this.filter = this.filterService.filter;
    this.subscriber = this.filterService.conditionsChanged().subscribe(() => this.ngOnConditionsChanged());
    // if (!vm.modalInstance) {
    //         $scope.$watch('vm.groupTags', function(newValue, oldValue){
    //           if (newValue && !angular.equals(newValue, oldValue)) {
    //             applyFilter();
    //           }
    //         }, true);
    //       }
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }

  ngOnConditionsChanged() {
    this.groupTags = this.filter.data.groupTags;
    this.setCheckedNodes(this.groupTags);
    this.setParents(this.groupTags);
  }

  checkAll(node: INode) {
    if (node.items && node.items.length > 0) {
      node.expanded = true;
      this.propagateChecked(node.items, node.checked);
    }

    // uncheck upward
    if (!node.checked) {
      let parent = node.parent;

      while (parent) {
        parent.checked = false;
        parent = parent.parent;
      }
    }
  }

  propagateChecked(nodes: INode[], checked: boolean) {
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].checked = checked;
      nodes[i].expanded = true;

      if (nodes[i].items && nodes[i].items.length > 0) {
        this.propagateChecked(nodes[i].items, checked);
      }
    }
  }

  getCheckedNodes(nodes: INode[]): ISearchFilterItem[] {
    const childNodes = _.reduce(
      nodes,
      (flattened, node) => {
        return flattened.concat(node.items || []);
      },
      [],
    );
    const checkedNodes = _.filter(childNodes, function (n) {
      return n.checked && n.id;
    });

    if (childNodes.length > 0) {
      return checkedNodes.concat(this.getCheckedNodes(childNodes));
    }

    return checkedNodes;
  }

  setParents(nodes: INode[], parent?: INode) {
    if (nodes && nodes.length > 0) {
      _.forEach(nodes, (node) => {
        node.parent = parent;
        this.setParents(node.items, node);
      });
    }
  }

  setCheckedNodes(nodes: INode[]) {
    let hasChecked = false;

    _.forEach(nodes, (node) => {
      node.checked = !!node.typeId && _.some(this.filter.selectedItems, { id: node.id });

      hasChecked = hasChecked || node.checked;

      if (this.setCheckedNodes(node.items)) {
        hasChecked = node.expanded = true;

        // restore label checked state
        if (!node.typeId) {
          node.checked = _.every(node.items, 'checked');
        }
      }
    });

    return hasChecked;
  }

  setCurrentNode(node: INode, selected: boolean) {
    node.checked = selected;

    if (node.items && node.items.length > 0) {
      this.propagateChecked(node.items, selected);
    }
  }

  applyFilter() {
    this.filterService.clear();

    _.forEach(this.getCheckedNodes(this.groupTags), (item) => {
      this.filterService.applyFilter(item.id.toString());
    });

    if (this.modalInstance) {
      this.modalInstance.close();
    }
  }

  cancelModal() {
    this.modalInstance?.dismiss();
  }
}
