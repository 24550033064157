import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { IGroupAchievement } from 'modules/admin/group/achievements/models/group-achievements.model';
import { IUser } from 'modules/user/models/user.model';
import { Unsubscribable, lastValueFrom } from 'rxjs';
import { ILeaderboardUserStats } from '../models/user-achievements.models';
import { UserAchievementsService } from '../services/user-achievements.service';

@Component({
  selector: 'user-achievements-scales',
  templateUrl: 'user-achievements-scales.component.html',
})
export class UserAchievementsScalesComponent implements OnInit, OnDestroy {
  static readonly selector = 'userAchievementsScales';

  @Input() user: IUser;
  @Input() trackingName: string;

  stats: ILeaderboardUserStats;
  userAchievements: IGroupAchievement[] = [];
  modalSubscriber?: Unsubscribable;
  maxCount = 3;
  uiBlocker = false;
  selfView: boolean;

  readonly currentUser: any;

  constructor(
    private userAchievementsService: UserAchievementsService,
    private currentUserService: CurrentUserService,
  ) {
    this.currentUser = this.currentUserService.get();
  }

  async ngOnInit(): Promise<void> {
    this.selfView = +this.user.id === +this.currentUser.id;

    this.userAchievementsService.getStats(this.user.id).subscribe((stats) => {
      this.stats = stats;
    });

    this.userAchievements = this.userAchievementsService
      .updateAchievementsForUser(
        await lastValueFrom(this.userAchievementsService.getAchievements(this.user.id)),
        await lastValueFrom(this.userAchievementsService.getAvailable(this.user.id)),
      )
      .filter((el) => el.achieved);

    this.modalSubscriber = this.userAchievementsService.loading.subscribe((value) => {
      this.uiBlocker = value;
    });
  }

  ngOnDestroy(): void {
    this.clearModalSubscriber();
    this.userAchievementsService.clearModalSubscriber();
  }

  showDetails(achievement: IGroupAchievement): void {
    if (!this.uiBlocker) {
      this.uiBlocker = true;
      this.userAchievementsService.openModal(this.user, achievement);
    }
  }

  clearModalSubscriber(): void {
    this.modalSubscriber?.unsubscribe();
    delete this.modalSubscriber;
  }
}
