import { Component, Input } from '@angular/core';
import { IEntityUser } from 'modules/user/models/user.model';

@Component({
  selector: 'user-access-keys-view',
  templateUrl: 'user-access-keys-view.component.html',
})
export class UserAccessKeysViewComponent {
  @Input() user: IEntityUser;
}
