import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ElmsUtils } from 'core/utils';
import _ from 'lodash';
import {
  ISearchFilter,
  ISearchFilterFetchResponse,
  ISearchFilterItem,
  ISearchFilterQuery,
  ISearchFilterService,
} from 'modules/search/models/search-filters.models';
import { Unsubscribable, finalize } from 'rxjs';

interface INode extends ISearchFilterItem {
  id: number;
  selected: boolean;
  expanded: boolean;
  nodes: INode[];
  parent: INode;
  typeId?: number;
  loading?: boolean;
}

@Component({
  selector: 'searchable-multi-choice-content',
  templateUrl: './searchable-multi-choice.component.html',
})
export class SearchableMultiChoiceComponent implements OnInit, OnDestroy {
  @Input() filterService: ISearchFilterService;

  filter: ISearchFilter;

  currentPage = 1;
  itemsPerPage = 10;
  items: INode[] = [];
  itemCount = null;
  query = '';
  searching = false;
  selectedItems = [];
  label: string;
  treeView = false;
  searchQuery: ISearchFilterQuery;

  private subscriber: Unsubscribable;

  constructor(private activeModal: NgbActiveModal) {}

  get isModal(): boolean {
    return !!this.activeModal;
  }

  ngOnInit(): void {
    this.filter = this.filterService.filter;
    this.label = this.filter.label;
    this.selectedItems = this.filter.selectedItems.filter((i) => !i.term).slice();

    this.initDefaultSearchQuery();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  initDefaultSearchQuery() {
    if (this.treeView) {
      this.searchQuery = {
        treeView: this.treeView,
      };

      if (this.query && this.query !== '') {
        this.searchQuery.name = this.query;
      }
    } else {
      this.searchQuery = {
        query: this.query,
        page: this.currentPage,
        rows: this.itemsPerPage,
      };
    }
  }

  isItemAdded(item: ISearchFilterItem) {
    return !!_.find(this.selectedItems, { id: item.id });
  }

  applyFilter() {
    const items = this.filter.items.filter((i) => i.term);
    const selectedItems = this.filter.selectedItems.filter((i) => !i.term);

    this.filter.items = items.concat(this.selectedItems);
    this.filterService.applyItems(_.xor(this.selectedItems, selectedItems));

    if (this.isModal) {
      this.activeModal.close();
    }
  }

  cancel() {
    this.activeModal?.dismiss();
  }

  search() {
    this.currentPage = 1;
    this.initDefaultSearchQuery();
    this.loadData();

    return false;
  }

  pageChanged(page: number) {
    if (this.itemCount <= this.items.length) {
      return;
    }

    this.currentPage = page;
    this.searchQuery.page = this.currentPage;

    this.loadData();
  }

  loadData() {
    this.searching = true;
    this.subscriber?.unsubscribe();
    this.subscriber = this.filterService
      .fetchData<ISearchFilterFetchResponse<INode>>(this.searchQuery)
      .pipe(finalize(() => this.unsubscribe()))
      .subscribe((data) => {
        this.itemCount = data.itemCount;
        this.items = data.items;
        this.items.forEach((item) => this.initItem(item));
      });
  }

  addItem(item: ISearchFilterItem) {
    const itemToAdd = _.find(this.selectedItems, { id: item.id });

    if (!itemToAdd) {
      item.selected = true;
      this.selectedItems.push(item);
    }

    if (!this.activeModal) {
      this.applyFilter();
    }
  }

  removeItem(item: ISearchFilterItem) {
    item.selected = false;
    _.remove(this.selectedItems, { id: item.id });

    if (!this.activeModal) {
      this.applyFilter();
    }
  }

  getPathToItem(item: ISearchFilterItem) {
    if (item.text === item.path) {
      return '';
    }

    return item.path.substring(0, item.path.length - item.text.length);
  }

  changeSearchType() {
    this.itemCount = null;
    this.items = [];
    this.treeView = !this.treeView;
    this.initDefaultSearchQuery();
    this.loadData();
  }

  initItem(item: ISearchFilterItem) {
    const itemAdded = _.find(this.selectedItems, { id: item.id });

    if (itemAdded) {
      item.selected = true;
    }
  }

  changeItem(item: ISearchFilterItem) {
    if (item.selected) {
      this.addItem(item);
    } else {
      this.removeItem(item);
    }
  }

  expand(node: INode) {
    if (node.nodes && node.nodes.length > 0) {
      node.expanded = !node.expanded;
    } else {
      node.loading = true;
      this.searchQuery = {
        parent: node.id,
        treeView: this.treeView,
      };

      this.searching = true;
      this.subscriber?.unsubscribe();
      this.subscriber = this.filterService
        .fetchData<ISearchFilterFetchResponse<INode>>(this.searchQuery)
        .pipe(finalize(() => this.unsubscribe()))
        .subscribe((data) => {
          node.nodes = data.items as INode[];
          node.expanded = !node.expanded;
          node.nodes.forEach((item) => this.initItem(item));
          node.loading = false;
        });
    }
  }

  escapeRegExp(str: string) {
    return ElmsUtils.escapeRegExp(str);
  }

  private unsubscribe() {
    this.searching = false;
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }
}
