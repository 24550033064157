import { Component, HostListener, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CurrentUserService } from 'core/authorization';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { IUser } from 'modules/user/models/user.model';
import { ILeaderboardUsers } from '../models/user-achievements.models';

@Component({
  selector: 'achievements-leaderboard',
  templateUrl: 'achievements-leaderboard.component.html',
})
export class AchievementsLeaderboardComponent implements OnInit {
  @Input() user: IUser;
  @Input() users: ILeaderboardUsers;

  chunkSize = 5;
  mobileView = false;
  selfView: boolean;

  readonly currentUser: any;

  constructor(
    public stateService: StateService,
    public backUrlService: BackUrlService,
    private currentUserService: CurrentUserService,
  ) {
    this.currentUser = this.currentUserService.get();
  }

  @HostListener('window:resize', ['$event']) resize(): void {
    this.mobileView = window.innerWidth < 768;
  }

  ngOnInit(): void {
    this.mobileView = window.innerWidth < 768;
    this.selfView = +this.user.id === +this.currentUser.id;
  }

  viewUserBadges(id: number): void {
    if (id !== this.user.id) {
      this.stateService.go('main.user_achievements', { userId: id });
    }
  }

  showBadges(): void {
    this.backUrlService.passThroughRedirect('main.user_achievements', { userId: this.user.id });
  }
}
