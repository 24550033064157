import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ISearchFilter, ISearchFilterItem, ISearchFilterService } from 'modules/search/models/search-filters.models';
import { SearchableMultiChoiceComponent } from './modal/searchable-multi-choice.component';

@Component({
  selector: 'searchable-multi-choice-filter',
  templateUrl: './searchable-multi-choice-filter.component.html',
})
export class SearchableMultiChoiceFilterComponent implements OnInit {
  @Input() filterService: ISearchFilterService;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;

  filter: ISearchFilter;

  private modalInstance?: NgbModalRef;
  constructor(
    private ngModal: NgbModal,
    private injector: Injector,
  ) {}

  get termItems(): ISearchFilterItem[] {
    return this.filter.items.filter((i) => i.term);
  }

  get selectedItems(): ISearchFilterItem[] {
    return this.filter.selectedItems.filter((i) => !i.term);
  }

  ngOnInit(): void {
    this.filter = this.filterService.filter;
  }

  openFilter() {
    this.modalInstance = this.ngModal.open(SearchableMultiChoiceComponent, {
      injector: this.injector,
    });

    const component = this.modalInstance.componentInstance as SearchableMultiChoiceComponent;

    component.filterService = this.filterService;
  }

  applyFilter(value: string, term: string) {
    this.filterService.applyFilter(value, term);
  }

  clearSelection() {
    this.filterService.clear();
  }
}
