import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { ISearchFilter, ISearchFilterService } from 'modules/search/models/search-filters.models';

@Component({
  selector: 'price-form',
  templateUrl: './price-form.component.html',
})
export class PriceFormComponent implements OnInit {
  static readonly selector = 'priceForm';

  @Input() filterService: ISearchFilterService;
  @Input() status: any;
  @Input() anyEvent?: boolean;
  @Output() applied = new EventEmitter<boolean>();

  filter: ISearchFilter;
  paid = false;

  ngOnInit() {
    this.filter = this.filterService.filter;
    this.paid = this.status.paid;
  }

  changeFilter() {
    this.status.changed =
      (this.status.paid &&
        (this.filter.items[0].value !== this.status.minPrice || this.filter.items[1].value !== this.status.maxPrice)) ||
      this.status.paid !== this.paid ||
      this.filter.items[2].value !== this.status.free;

    if (this.anyEvent) {
      this.applyFilter();
    }
  }

  applyFilter() {
    const updatedItems = [];
    let minPrice;
    let maxPrice;

    // save filter values
    if (this.status.paid) {
      minPrice = this.status.minPrice || 0;
      maxPrice = _.isNumber(this.status.maxPrice) ? this.status.maxPrice : '';
    } else {
      minPrice = null;
      maxPrice = null;
    }

    if (this.filter.items[0].value !== minPrice) {
      this.filter.items[0].value = minPrice;
      updatedItems.push(this.filter.items[0]);
    }

    if (this.filter.items[1].value !== maxPrice) {
      this.filter.items[1].value = maxPrice;
      updatedItems.push(this.filter.items[1]);
    }

    if (this.filter.items[2].value !== this.status.free) {
      this.filter.items[2].value = this.status.free ? this.status.free : null;
      updatedItems.push(this.filter.items[2]);
    }

    this.paid = this.status.paid;
    // apply current selection
    const items = _.cloneDeep(updatedItems);

    // Do not need clear because apply only changed items
    // _.forEach(items, (item) => {
    //   this.filterService.apply(item.value, item.term);
    // });
    this.filterService.applyItems(items);

    // clear variables
    this.status.isOpen = false;
    this.status.changed = false;

    this.applied.emit(true);
  }
}
