import { Component, Input } from '@angular/core';
import { ISearchFilterService } from 'modules/search/models/search-filters.models';

@Component({
  selector: 'search-filter-view',
  templateUrl: './search-filter-view.component.html',
})
export class SearchFilterViewComponent {
  @Input() filterService: ISearchFilterService;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
}
