import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { IResourceCategoryPath } from 'modules/resources/models/resource-category.model';
import { ResourceCategoryService } from 'modules/resources/services/resource-category.service';
import { Unsubscribable, finalize, tap } from 'rxjs';

@Component({
  selector: 'resource-category-path',
  templateUrl: './category-path.component.html',
})
export class ResourceCategoryPathComponent implements OnInit, OnDestroy {
  @Input() categoryId!: number;
  @Input() includeCurrent?: boolean;
  @Input() readonlyView?: boolean;

  categories: IResourceCategoryPath[];

  private querySubscriber: Unsubscribable;

  constructor(protected categoryService: ResourceCategoryService) {}

  ngOnInit() {
    this.querySubscriber = this.categoryService
      .getPath(this.categoryId)
      .pipe(
        tap((categories) => {
          this.categories = this.includeCurrent ? categories : _.initial(categories);
        }),
        finalize(() => {
          this.querySubscriber.unsubscribe();
          delete this.querySubscriber;
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.querySubscriber?.unsubscribe();
    delete this.querySubscriber;
  }
}
