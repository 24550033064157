import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'keyword-search-form',
  templateUrl: './keyword-search-form.component.html',
})
export class KeywordSearchFormComponent {
  @Input() value: string;
  @Input() placeholder = 'Search keyword';
  @Input() disabled?: boolean;
  @Input() pending?: boolean;
  @Input() autoFocus? = true;
  @Input() minLength? = 0;
  @Output() valueChange = new EventEmitter<string>();
  @Output() formSubmit = new EventEmitter<void>();

  onSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    this.onChange(this.value);
    this.formSubmit.emit();
  }

  onChange(value: string) {
    this.valueChange.emit(value?.trim());
  }
}
