import { Component, Input, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import {
  ISearchFilterTypes,
  ISearchFilterUpgraded,
  ISearchFilterValueTerm,
} from 'app/deprecated/search/models/search-filters.models';
import { BaseFilterService } from 'app/deprecated/search/services/filters-old/search-filtering.service.ajs-upgraded';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import _ from 'lodash';
import { IMenuItem } from 'modules/search/models/search.model';
import moment from 'moment';

@Component({
  selector: 'full-screen-filter-view',
  templateUrl: './full-screen-filter-view.component.html',
})
export class FullScreenFilterViewComponent implements OnInit {
  static readonly selector = 'searchFullScreenFilters';

  @Input() filters: ISearchFilterTypes;
  @Input() returnState?: string;

  allFilters: ISearchFilterUpgraded[];
  sortTerms: ISearchFilterValueTerm[] = this.searchFilters.sortTerms;
  mainMenuItems: IMenuItem[] = [];
  menuItem: IMenuItem;
  currentFilter: ISearchFilterUpgraded;
  sort: string;
  title: string;

  readonly defaultSort = 'relevance';

  constructor(
    private urlService: UrlService,
    private backUrlService: BackUrlService,
    private stateService: StateService,
    private activeState: UIRouterGlobals,
    private searchFilters: BaseFilterService,
  ) {}

  ngOnInit(): void {
    this.allFilters = this.filters.ui?.concat(this.filters.advanced ?? []);
    this.filters.all.forEach((filter) => {
      filter.extractConditions();
    });

    this.sort = this.urlService.search()['sort'] ?? this.defaultSort;
    this.mainMenuItems = this.buildMenuItems();
    this.selectMenuItem();
  }

  buildMenuItems(): IMenuItem[] {
    const items: IMenuItem[] = [];

    if (this.allFilters.length) {
      items.push({
        name: 'Filter',
        mode: 'filter',
        selectionNote: () => {
          let selectedFilters = 0;

          for (let i = 0; i < this.allFilters.length; i++) {
            selectedFilters += _.get(this.allFilters[i], 'selectedItems.length', 0);
          }

          return selectedFilters ? selectedFilters + ' filter(s) selected' : '';
        },
      });
    }

    items.push({
      name: 'Sort',
      mode: 'sort',
      selectionNote: () => {
        const sort = _.find(this.sortTerms, { value: this.sort });

        return sort ? sort.name : '';
      },
    });

    return items;
  }

  formatDate(date: string | number): string {
    return moment(date).format('MM/DD/YYYY');
  }

  isNumber(value: any): boolean {
    return _.isNumber(value);
  }

  selectMenuItem(menuItem?: IMenuItem): void {
    this.menuItem = menuItem;
    this.setTitle(menuItem ? menuItem.name : this.allFilters.length ? 'Filter & Sort' : 'Sort');
  }

  setTitle(title: string): void {
    this.title = title;
  }

  selectFilter(filter: ISearchFilterUpgraded): void {
    this.currentFilter = filter;
    this.setTitle(this.currentFilter.label);
  }

  showResults(): void {
    this.filters.all.forEach((filter) => {
      filter.extractConditions();
    });

    this.stateService.go(this.returnState || 'main.search', this.urlService.search());
  }

  resetFilters(): void {
    if (this.currentFilter) {
      this.currentFilter.clearSelection();
    } else {
      this.filters.all.forEach((filter: ISearchFilterUpgraded) => {
        if (filter.restoreDefaults) {
          filter.restoreDefaults();
        }

        filter.clearSelection();
        filter.extractConditions();
      });
    }
  }

  selectSortTerm(term?: ISearchFilterValueTerm): void {
    this.sort = term ? term.value : this.defaultSort;
    this.adjustSearchQuery();
  }

  adjustSearchQuery(): void {
    const searchQuery = _.pickBy(this.urlService.search(), _.identity);

    Object.assign(searchQuery, {
      sort: this.sort !== this.defaultSort ? this.sort : '',
    });

    // Clear empty params
    _.pickBy(searchQuery, (n) => {
      return !!n;
    });

    if (!_.isEqual(this.urlService.search(), searchQuery)) {
      // Update url query
      this.stateService.go(this.activeState.current, searchQuery);
    }
  }

  closeDialog(): void {
    if (!this.menuItem) {
      this.backUrlService.goBack();
    } else {
      if (!this.currentFilter) {
        this.selectMenuItem();
      } else {
        this.currentFilter = null;
        this.setTitle(this.menuItem.name);
      }
    }

    this.filters.all.forEach((filter) => {
      filter.extractConditions();
    });
  }
}
