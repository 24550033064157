<div *ngIf="filter.visible && filter.items.length">
  <div *ngIf="!fullScreen">
    <div ngbAccordion *ngIf="filter.appearance === 'panel'" class="search-filter-group">
      <div ngbAccordionItem [collapsed]="!isOpen">
        <div ngbAccordionHeader>
          <button type="button" ngbAccordionButton>
            <span class="pull-right fa fa-fw fa-caret-down"></span>
            <span>{{ filter.label }}</span>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <button
                type="button"
                class="btn-link"
                (click)="selectAll(filteredFilterItems)"
                title="Select all items"
                *ngIf="filter.allowSelectAll">
                {{ isAllSelected() ? 'Unselect All' : 'Select All' }}
              </button>
              <div
                class="content-list-item search-item search-input"
                *ngIf="filter.items.length > (filter.displayLimit || 10)">
                <input
                  type="search"
                  [(ngModel)]="searchQuery"
                  (change)="searchQueryChanged()"
                  name="search"
                  #searchQueryModel="ngModel"
                  class="form-control"
                  placeholder="Filter items"
                  [id]="'filterSearch-' + filter.label" />
                <label [for]="'filterSearch-' + filter.label" class="sr-only">Filter</label>
              </div>

              <div>
                <div *ngFor="let item of filteredFilterItems" class="checkbox">
                  <label>
                    <input type="checkbox" [(ngModel)]="item.selected" (change)="applyFilter(item.value, item.term)" />
                    <span [innerHtml]="item.text" class="word-wrap word-break"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="filter.unassignedOption && (!filter.displayLimit || filter.items.length <= displayLimit)">
                <hr class="no-margin-top no-margin-bottom" />
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      [(ngModel)]="filter.unassignedOption.selected"
                      (change)="applyFilter(filter.unassignedOption.value)" />
                    {{ filter.unassignedOption.text }}</label
                  >
                </div>
              </div>

              <!--Expand/Collapse-->
              <div *ngIf="!searchQuery?.length">
                <button
                  type="button"
                  title="View more"
                  class="text-left btn-link"
                  *ngIf="filter.displayLimit && filter.items.length > displayLimit"
                  (click)="moreItems()">
                  more...
                </button>
                <button
                  type="button"
                  title="View less"
                  class="text-left btn-link"
                  *ngIf="filter.displayLimit && filter.displayLimit < displayLimit"
                  (click)="lessItems()">
                  less
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="filter.appearance === 'button'"
      class="btn-group btn-block"
      #dropDown="ngbDropdown"
      ngbDropdown
      [autoClose]="'outside'">
      <button
        id="SearchFilters"
        type="button"
        ng-init="displayLimit = filter.displayLimit;"
        class="btn btn-default btn-block"
        ngbDropdownToggle>
        {{ filter.label }} <span class="fa fa-caret-down"></span>
      </button>

      <div ngbDropdownMenu>
        <div aria-labelledby="SearchFilters" class="dropdown-filter-items">
          <div
            class="checkbox dropdown-filter-item"
            role="menuitem"
            *ngFor="let item of filter.items.slice(0, displayLimit)">
            <label
              ><input type="checkbox" [(ngModel)]="item.selected" (change)="setStatus()" /><span
                [innerHtml]="item.text"></span
            ></label>
          </div>
          <div class="checkbox" *ngIf="filter.items.length > displayLimit">
            <button type="button" class="btn-link text-left" title="more" (click)="moreItems()">more...</button>
          </div>
        </div>
        <div class="divider"></div>
        <div class="checkbox dropdown-filter-item" style="padding: 10px">
          <button
            type="button"
            [disabled]="!status.changed"
            class="btn btn-default btn-block"
            (click)="applyFilterItems(); dropDown.close()">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen">
    <ul class="content-list">
      <li class="content-list-item search-item" *ngIf="filter.allowSelectAll">
        <button type="button" class="btn-link" (click)="selectAll(filteredFilterItems)" title="Select all items">
          {{ isAllSelected() ? 'Unselect All' : 'Select All' }}
        </button>
      </li>
      <li class="content-list-item search-item search-input">
        <input
          id="filterSearch0"
          type="search"
          [(ngModel)]="searchQuery"
          (change)="searchQueryChanged()"
          class="form-control"
          placeholder="Filter items" />
        <span class="fa fa-search"></span>
        <label for="filterSearch0" class="sr-only">Filter</label>
      </li>
      <li
        class="content-list-item search-item"
        tabindex="0"
        (keypress.enter)="applyFilter(item.value, item.term)"
        (click)="applyFilter(item.value, item.term)"
        [ngClass]="{ 'search-item-selected': item.selected }"
        *ngFor="let item of filteredFilterItems">
        <div class="flex flex-vertical-center">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              {{ item.text }}
            </div>
            <div class="flex-col flex-col-2x text-right text-muted">
              <span class="fa fa fa-check" *ngIf="item.selected"></span>
            </div>
          </div>
        </div>
      </li>
      <li
        class="content-list-item search-item"
        *ngIf="filter.unassignedOption"
        [ngClass]="{ 'search-item-selected': filter.unassignedOption.selected }">
        <div class="flex flex-vertical-center">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              <label class="full-width no-margin-bottom">
                <input
                  type="checkbox"
                  class="display-none"
                  [(ngModel)]="filter.unassignedOption.selected"
                  (change)="applyFilter(filter.unassignedOption.value)" />
                {{ filter.unassignedOption.text }}

                <div class="inline-block pull-right" *ngIf="filter.unassignedOption.selected">
                  <span class="fa fa fa-check"></span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
