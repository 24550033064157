import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { GlobalConfig } from 'core/environment';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { SecurityService } from 'core/services';
import { IGroupAchievement } from 'modules/admin/group/achievements/models/group-achievements.model';
import { IUser } from 'modules/user/models/user.model';
import { Unsubscribable } from 'rxjs';
import { ILeaderboardUserStats, ILeaderboardUsers, IUserAchieved } from '../models/user-achievements.models';
import { UserAchievementsService } from '../services/user-achievements.service';

@Component({
  selector: 'user-achievements',
  templateUrl: 'user-achievements.component.html',
})
export class UserAchievementsComponent implements OnInit, OnDestroy {
  @Input() user: IUser;
  @Input() stats: ILeaderboardUserStats;
  @Input() userAchievements: IUserAchieved[] = [];
  @Input() availableAchievements: IGroupAchievement[] = [];
  @Input() trackingName: string;

  leaderboardUsers: ILeaderboardUsers;
  leaderboardEnabled = false;
  modalSubscriber?: Unsubscribable;
  simpleTiles = true;
  mobileView = false;
  uiBlocker = false;
  percentage: number;

  readonly isStateAvailable = this.securityService.isStateAvailable;
  readonly currentUser: any;

  constructor(
    public stateService: StateService,
    private currentUserService: CurrentUserService,
    private globalConfig: GlobalConfig,
    private userAchievementsService: UserAchievementsService,
    private securityService: SecurityService,
    private backUrlService: BackUrlService,
  ) {
    this.currentUser = this.currentUserService.get();
  }

  @HostListener('window:resize', ['$event']) resize(): void {
    this.mobileView = window.innerWidth < 768;
  }

  async ngOnInit(): Promise<void> {
    this.mobileView = window.innerWidth < 768;
    this.simpleTiles = +this.user.id !== +this.currentUser.id;
    this.leaderboardEnabled = this.globalConfig.settings.achievementsConfig?.leaderboardEnabled;
    this.percentage = this.availableAchievements.length
      ? (this.userAchievements.length / this.availableAchievements.length) * 100
      : 0;
    this.leaderboardUsers = await this.userAchievementsService.getLeaderboardUsers(this.stats);

    this.modalSubscriber = this.userAchievementsService.loading.subscribe((value) => {
      this.uiBlocker = value;
    });
  }

  ngOnDestroy(): void {
    this.clearModalSubscriber();
    this.userAchievementsService.clearModalSubscriber();
  }

  showLeaderboard(): void {
    if (this.stateService.is('main.user_achievements')) {
      this.backUrlService.passThroughRedirect('main.leaderboard', { id: this.user.id });
    } else {
      this.stateService.go('main.leaderboard', { id: this.user.id });
    }
  }

  openModal(achievement: IGroupAchievement): void {
    if (!this.uiBlocker) {
      this.uiBlocker = true;
      this.userAchievementsService.openModal(this.user, achievement);
    }
  }

  clearModalSubscriber(): void {
    this.modalSubscriber?.unsubscribe();
    delete this.modalSubscriber;
  }
}
