import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICompetency } from 'modules/competency/models/competency.model';
import { ISearchFilter, ISearchFilterItem, ISearchFilterService } from 'modules/search/models/search-filters.models';
import { Unsubscribable } from 'rxjs';

@Component({
  selector: 'competency-filter',
  templateUrl: './competency-filter.component.html',
})
export class CompetencyFilterComponent implements OnInit, OnDestroy {
  @Input() filterService: ISearchFilterService;

  filter: ISearchFilter;
  competencies: ICompetency[];
  selectedItems: ISearchFilterItem[];
  modalInstance?: NgbActiveModal;

  private subscriber: Unsubscribable;

  get isModal(): boolean {
    return !!this.modalInstance;
  }

  ngOnInit(): void {
    this.filter = this.filterService.filter;
    this.subscriber = this.filterService.conditionsChanged().subscribe(() => this.ngOnConditionsChanged());
    // TODO Temporary solution for large list rendering until the <competencies-tree-view> directive is implemented.
    setTimeout(() => (this.competencies = this.filter.data.competencies || []));

    if (this.isModal) {
      this.ngOnConditionsChanged();
    }
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }

  ngOnConditionsChanged() {
    // this.competencies = this.filter.data.competencies || [];
    this.selectedItems = this.filter.selectedItems;
  }

  selectedItemsChanged(items: ISearchFilterItem[]) {
    // Prevent ExpressionChangedAfterItHasBeenCheckedError
    // from the upgraded component's bindings
    requestAnimationFrame(() => {
      this.selectedItems = items;

      if (!this.modalInstance) {
        this.applyFilter();
      }
    });
  }

  applyFilter() {
    const selectedItems = this.selectedItems.map(({ id }) => ({ id, text: '', value: id.toString() }));

    this.filterService.applyItems(selectedItems);

    if (this.modalInstance) {
      this.modalInstance.close();
    }
  }

  cancelModal() {
    this.modalInstance?.dismiss();
  }
}
