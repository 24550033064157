import { Component, Input } from '@angular/core';
import { IExternalApplicationUser } from 'modules/external-applications/models/external-application-user.model';

@Component({
  selector: 'external-application-user-view',
  templateUrl: 'external-application-user-view.component.html',
})
export class ExternalApplicationUserViewComponent {
  @Input() userApplication: IExternalApplicationUser;
}
